import nft1 from '../../../../assets/images/freezeNfts/nft1.png'
import nft2 from '../../../../assets/images/freezeNfts/nft2.png'
import nft3 from '../../../../assets/images/freezeNfts/nft3.png'
import nft4 from '../../../../assets/images/freezeNfts/nft4.png'

export const nftBoxData = [
	nft1,
	nft2,
	nft3,
	nft1,
	nft2,
	nft3,
	nft1,
	nft2,
	nft3,
	nft1
]
