export const sliderHourseData = [
	{
		value: 12,
		label: '12',
	},
	{
		value: 24,
		label: '24',
	},
	{
		value: 36,
		label: '36',
	},
	{
		value: 48,
		label: '48',
	},
	{
		value: 60,
		label: '60',
	},
	{
		value: 72,
		label: '72',
	},
	{
		value: 84,
		label: '84',
	},{
		value: 96,
		label: '96',
	}
	,{
		value: 108,
		label: '108',
	},
	{
		value: 120,
		label: '120',
	},
	{
		value: 132,
		label: '132',
	},
]